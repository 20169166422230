import Config from "../../config";
import { UserModel, UserStatus } from "../../models/users.model";
import { AppState } from "../../reducers/models";
import { UtilityService } from "../../services/UtilityService";

export class ConversationModel {
    constructor() {
    }

    ID: string;
    IsArchived: boolean;
    IsOutbound: boolean;
    FromPhoneNumber?: string;
    PhoneNumber: string;
    MaskedPhoneNumber: string;
    Media?: MediaModel;
    Message: string | null;
    MessageDateTime: string;
    SenderID: string;
    UnReadCount: number;
    Contact?: Contact;
    IsPinned: boolean;
    IsBlocked: boolean;
    IsUnsubscribed: boolean;
    DeliveryStatus: DeliveryStatus;
    IsMuted: boolean;
    GroupChat?: GroupChatModel;
    GroupChatId?: string
}

export enum DeliveryStatus {
    NONE, PENDING, SENT, FAILED, RECEIVED, SENDING,
}

export class Contact {
    constructor() {
    }

    ID: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Name: string;
    PhoneNumber: string;
    MaskedPhoneNumber: string;
    Groups?: Group[];
    IsBlocked: boolean;
    IsUnsubscribed: boolean;
    IsDeleted: boolean;
    IsPublic: boolean;
    IsPinned: boolean;
    UnReadCount: number;
    CreatedDateTime: string;
    Errors?: ContactError;
    ContactStatus: ContactStatus
    Source: string;
    CreatedById: string;
    UpdatedById: string

}

export enum ContactStatus {
    ACTIVE = 1,
    BLOCKED,
    UNSUBSCRIBED,
}

export class ContactError {
    constructor() {
    }
    FirstName: string;
    ContactLastName: string;
    PhoneNumber: string;
    ContactEmail: string
}

export class Group {
    constructor() {
    }

    ID: string;
    Name: string;
    CreatedDateTime: string;
    ContactsCount: number;
    TotalActiveContacts: number;
    TotalBlockedContacts: number;
    TotalUnsubscribedContacts: number;
    Contacts?: Contact[];
    IsDeleted?: boolean;
}

export class ContactType {
    IsPinned: boolean;
    IsArchived: boolean;
    CreatedDateTime: string;
    MutedTill: string;
    PhoneNumber: string;
}

export class MediaModel {
    constructor() {
    }

    ID: string;
    Name: string;
    Url: string;
    ContentType: string;
}

export class GroupChatModel {
    ID: string;
    PhoneNumbers: string[];
    Name: string;
    UnReadCount: number;
}



export const mapToGroupChat = (groupchat: any): GroupChatModel => {
    return {
        ID: groupchat.id,
        PhoneNumbers: groupchat.phoneNumbers,
        Name: groupchat.name,
        UnReadCount: groupchat.unReadCount,
    } as GroupChatModel;
};

export class DeletedGroupContactData {

    DeletedContacts: Contact[];
    DeletedContactsCount: number;
    NonDeletedContacts: NonDeletedIds[];
    TotalContactsCount: number;
}

export class NonDeletedIds {
    ContactId: string;
    GroupIds: string[];
}

export class TypingDataModel {
    ReceiverPhoneNumber: string;
    Action: TypeAction;
    UserID: string;
}
export class OutLookResponse {
    flag: boolean;
    totalContacts: number;
    ignoredContacts: number;
    savedContacts: number;
    existingContacts: number;
    error: string;
    loadContacts: boolean
}



export enum MassActionStatus {
    DeleteGroupsStarted = "DeleteGroupsStarted",
    RefreshContactsAndGroups = "RefreshContactsAndGroups",
    MassActionStarted = "MassActionStarted",
    RefreshContacts = "RefreshContacts"
}


export enum TypeAction {
    START_TYPING = "StartTyping",
    STOP_TYPING = "StopTyping",
}
export const mapToDeletedGroupContactData = (data: any): DeletedGroupContactData => {
    return {
        DeletedContacts: data.deletedContacts.map(mapToContact),
        DeletedContactsCount: data.deletedContactsCount,
        NonDeletedContacts: data.map(mapToNonDeletedIds),
        TotalContactsCount: data.totalContactsCount
    }
}

export const mapToNonDeletedIds = (data: any): NonDeletedIds => {
    return {
        ContactId: data.contactId,
        GroupIds: data.groupIds.map(x => x)
    }
}

export const mapToConverstion = (x: any): ConversationModel => {
    return {
        ID: x.id,
        PhoneNumber: !x.isOutbound && !!x.groupChat?.id ? x.groupChat?.id : x.phoneNumber,
        FromPhoneNumber: !!x.groupChat?.id ? x.contact?.phoneNumber : !!x.groupChatId ? x.phoneNumber : "",
        MaskedPhoneNumber: UtilityService.maskPhoneNumberOnLength(x.phoneNumber),
        Message: x.message,
        MessageDateTime: x.messageDateTime,
        IsPinned: x.pinned,
        IsArchived: x.archived,
        IsUnsubscribed: x.isUnSubscribed,
        IsBlocked: x.isBlocked,
        IsOutbound: x.isOutbound,
        SenderID: x.senderId,
        UnReadCount: x.unReadCount,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : null,
        DeliveryStatus: x.statusId as DeliveryStatus,
        IsMuted: false,
        GroupChat: x.groupChat ? { ID: x.groupChat.id, PhoneNumbers: x.groupChat.phoneNumbers, Name: x.groupChat.name, UnReadCount: x.groupChat.unReadCount } as GroupChatModel : null,
        GroupChatId: !!x.groupChatId ? x.groupChatId : !!x.groupChat ? x.groupChat.id : null,
    }
}

export const mapToContact = (contact: any): Contact => {
    return {
        ID: contact.id || contact.contactId,
        Name: (contact.firstName === null && contact.lastName === null) ? '' : contact.name ? contact.name.trim() : `${contact.firstName} ${contact.lastName}`.trim(),
        Email: contact.email || "",
        IsUnsubscribed: contact.isUnsubscribed,
        IsBlocked: contact.isBlocked,
        PhoneNumber: contact.phoneNumber,
        CreatedDateTime: contact.createdDateTime,
        FirstName: contact.firstName !== null ? contact.firstName : '',
        LastName: contact.lastName !== null ? contact.lastName : '',
        MaskedPhoneNumber: contact.phoneNumber ? UtilityService.maskPhoneNumberOnLength(contact.phoneNumber) : "",
        Errors: contact.errors as ContactError,
        IsDeleted: !!contact.isDeleted,
        UnReadCount: contact.unReadCount,
        ContactStatus: contact.isUnsubscribed ? ContactStatus.UNSUBSCRIBED : contact.isBlocked ? ContactStatus.BLOCKED : ContactStatus.ACTIVE,
        Source: contact.source,
        CreatedById: contact.createdById,
        UpdatedById: contact.updatedById

    } as Contact;
};


export const mapToContactType = (x: any): ContactType => {
    return {
        IsPinned: x.isPinned,
        IsArchived: x.isArchived,
        PhoneNumber: x.phoneNumber,
        CreatedDateTime: x.createdDateTime,
        MutedTill: x.muteDateTime
    } as ContactType;
};

export const mapToUser = (x: any): UserModel => {
    return {
        ID: x.id,
        CreatedDateTime: x.createdDateTime,
        Email: x.email,
        FirstName: x.firstName,
        ImageReferenceID: x.imageReferenceId,
        IsDeleted: x.isDeleted,
        LastLoggedIn: x.lastLoggedIn,
        LastName: x.lastName,
        OrganizationID: x.organizationId,
        Password: x.password,
        PhoneNumber: x.phoneNumber,
        PhoneNumberID: x.phoneNumberId,
        RoleID: parseInt(x.roleId),
        UserStatus: x.lastLoggedIn === null ? UserStatus.PENDING : x.isDeleted ? UserStatus.LOCKED : UserStatus.ACTIVE
    } as UserModel;
};

export const mapToGroup = (x: any): Group => {
    return {
        ID: x.id,
        Name: x.name,
        CreatedDateTime: x.createdDateTime,
        ContactsCount: x.contacts ? x.contacts.length : x.count,
        Contacts: x.contacts ? x.contacts.map(mapToContact) : [],
        IsDeleted: x.isDeleted,
        TotalActiveContacts: 0,
        TotalBlockedContacts: 0,
        TotalUnsubscribedContacts: 0,
    } as Group;
}

export const sortConversations = (cons: ConversationModel[]): ConversationModel[] => {
    return [
        ...cons.filter(x => x.IsPinned).sort((current: ConversationModel, previous: ConversationModel) => current.IsPinned && previous.IsPinned ? 0 : current.IsPinned && !previous.IsPinned ? -1 : 1),
        ...cons.filter(x => !x.IsPinned).sort((current: ConversationModel, previous: ConversationModel) => new Date(current.MessageDateTime) > new Date(previous.MessageDateTime) ? -1 : new Date(current.MessageDateTime) < new Date(previous.MessageDateTime) ? 1 : 0),
    ];
};

export const sortContacts = (contacts: Contact[]): Contact[] => {
    return [
        ...contacts.sort((current: Contact, previous: Contact) => new Date(current.CreatedDateTime) > new Date(previous.CreatedDateTime) ? -1 : new Date(current.CreatedDateTime) < new Date(previous.CreatedDateTime) ? 1 : 0),

    ];
};

export const updateContactTypesInConversations = (
    conversations: ConversationModel[],
    contactTypes: ContactType[]
): ConversationModel[] => {
    const currentTime = UtilityService.getPreferredTimeZoneTimeNow();

    const contactTypeMap = new Map(
        contactTypes.map(ct => [ct.PhoneNumber, ct])
    );

    const updatedConversations = conversations.map(conversation => {
        conversation.IsArchived = false;
        conversation.IsPinned = false;
        conversation.IsMuted = false;

        const key = conversation.GroupChat?.ID || conversation.PhoneNumber;
        const matchingContactType = contactTypeMap.get(key);

        if (matchingContactType) {
            conversation.IsArchived = matchingContactType.IsArchived;
            conversation.IsPinned = matchingContactType.IsPinned;
            conversation.IsMuted = new Date(matchingContactType.MutedTill) >= currentTime;

            if (conversation.Contact) {
                conversation.Contact.IsPinned = matchingContactType.IsPinned;
            }
        }
        return conversation;
    });

    return sortConversations(updatedConversations);
};

export const updateContactsInConversations = (
    conversations: ConversationModel[],
    contacts: Contact[]
): ConversationModel[] => {
    const contactMap = new Map(
        contacts.map(contact => [UtilityService.unmaskPhoneNumber(contact.PhoneNumber), contact])
    );

    conversations.forEach(con => {
        const unmaskedPhoneNumber = UtilityService.unmaskPhoneNumber(con.PhoneNumber);
        const matchingContact = contactMap.get(unmaskedPhoneNumber);
        if (matchingContact) {
            con.Contact = matchingContact;
        }
    });

    return sortConversations(conversations);
};

export const pushConversation = (conversations: ConversationModel[], contacts: Contact[], newCons: ConversationModel[]): ConversationModel[] => {
    const conversationMap = new Map<string, ConversationModel>();
    const contactMap = new Map<string, Contact>();

    conversations.forEach(con => {
        const key = getConversationKey(con);
        conversationMap.set(key, con);
    });

    contacts.forEach(contact => {
        const key = UtilityService.unmaskPhoneNumber(contact.PhoneNumber);
        contactMap.set(key, contact);
    });

    const updatedConversations = conversations.slice();

    newCons.forEach(newCon => {
        const key = getConversationKey(newCon);
        const oldCon = conversationMap.get(key);

        if (oldCon) {
            newCon.IsPinned = oldCon.IsPinned;
            newCon.IsMuted = oldCon.IsMuted;
            newCon.IsBlocked = oldCon.IsBlocked;
            newCon.Contact = oldCon.Contact;
            newCon.IsArchived = oldCon.IsArchived;
        } else {
            const number = UtilityService.unmaskPhoneNumber(newCon.PhoneNumber);
            newCon.Contact = contactMap.get(number);
        }

        const index = updatedConversations.findIndex(x => getConversationKey(x) === key);
        if (index !== -1) {
            updatedConversations.splice(index, 1);
        }
        updatedConversations.push(newCon);
    });

    return sortConversations(updatedConversations);
};

const getConversationKey = (con: ConversationModel): string => {
    if (con.GroupChat?.ID) return `groupchat:${con.GroupChat.ID}`;
    return `phone:${UtilityService.unmaskPhoneNumber(con.PhoneNumber)}`;
};

export const pushConversationInContact = (contacts: Contact[], newCons: ConversationModel[]): Contact[] => {
    let cons = [...contacts]
    newCons.forEach(newCon => {
        let contact = contacts.find(x => UtilityService.unmaskPhoneNumber(x.PhoneNumber) === UtilityService.unmaskPhoneNumber(newCon.PhoneNumber));
        if (contact) {
            contact.IsPinned = newCon.IsPinned;
            contact.IsUnsubscribed = newCon.IsUnsubscribed;
        }
        cons = [...contacts]
    })

    return sortContacts(cons);
}


export const pushHistory = (history: { [key: string]: ConversationModel[] }, newCons: ConversationModel[]): { [key: string]: ConversationModel[] } => {
    newCons.forEach(newCon => {
        let historyToUpdate = history[!!newCon.GroupChat?.ID ? newCon.GroupChat?.ID : newCon.PhoneNumber.replace("+", '')];
        if (historyToUpdate && historyToUpdate.length) {
            // let nonIdMsg = historyToUpdate?.filter(x => !x.ID  ).filter(x=>(x.ID !== newCon.ID))
            historyToUpdate = historyToUpdate?.filter(x => (x.ID !== newCon.ID) && x.ID)
            // historyToUpdate=historyToUpdate.concat(nonIdMsg)
            historyToUpdate?.push(newCon);
            history[!!newCon.GroupChat?.ID ? newCon.GroupChat?.ID : newCon.PhoneNumber.replace("+", '')] = historyToUpdate;
        }
        // else{
        //     if(newCon.IsOutbound){                
        //         history[!!newCon.GroupChat?.ID ? newCon.GroupChat?.ID : newCon.PhoneNumber.replace("+", '')]=newCons
        //     }  
        // }
    })
    return { ...history };
}

export const handlePushContacts = (state: AppState, action): AppState => {
    let { Conversations, Contacts, AllContacts } = state;
    action.payload.forEach((contact: Contact) => {
        let _conversation = Conversations.find(x => x.PhoneNumber === contact.PhoneNumber || x.Contact?.ID === contact.ID);
        let _contact = Contacts.find(x => x.ID === contact.ID);
        if (_conversation) {
            _conversation.UnReadCount = contact.UnReadCount;
            _conversation.IsBlocked = contact.IsDeleted ? _conversation.IsBlocked : contact.IsBlocked;
        }

        if (_conversation?.Contact?.ID === contact.ID && (contact.IsDeleted || contact.MaskedPhoneNumber !== _conversation?.Contact?.MaskedPhoneNumber)) {
            _conversation.Contact = undefined;
        }

        if (_contact) {
            if (_contact.IsBlocked !== contact.IsBlocked) {
                _contact.IsBlocked = contact.IsBlocked;
                _contact.CreatedDateTime = contact.CreatedDateTime;
            }
            _contact.UnReadCount = contact.UnReadCount;
            _contact.IsDeleted = contact.IsDeleted;
        }
    });

    Contacts = Contacts.map(con => ({
        ...con,
        ...action.payload.find(payload => (payload.ID === con.ID))
    }))

    Contacts = Contacts.filter(e => (!e.IsDeleted))

    AllContacts = AllContacts.map(con => ({
        ...con,
        ...action.payload.find(payload => payload.ID === con.ID)
    }))

    Contacts = [...Contacts, ...action.payload.filter(x => !Contacts.map(y => y.ID).includes(x.ID))]
    AllContacts = [...AllContacts, ...action.payload.filter(x => !AllContacts.map(y => y.ID).includes(x.ID))]

    return {
        ...state,
        Conversations: [...Conversations],
        Contacts: sortContacts([...Contacts]),
        AllContacts: sortContacts([...AllContacts]),
        Groups: updateContactsInGroup(state.Groups, Contacts),
        Settings: {
            ...state.Settings,
            BlockedContacts: Contacts.filter((x: Contact) => x.IsBlocked),
            UnSubscribedContacts: Contacts.filter((x: Contact) => x.IsUnsubscribed)
        }
    };
}


export const updateContactsInGroup = (groups: Group[], contacts: Contact[]): Group[] => {
    if (contacts.length) {
        groups.forEach(x => {
            let newContacts = [...contacts.filter(y => x.Contacts?.map(z => z.ID).includes(y.ID))];
            let oldContacts = x.Contacts?.filter(y => !newContacts.map(z => z.ID).includes(y.ID))
            x.Contacts = newContacts.concat(oldContacts);
            x.TotalActiveContacts = newContacts.filter(y => (!y.IsBlocked && !y.IsUnsubscribed)).length;
            x.TotalBlockedContacts = newContacts.filter(y => y.IsBlocked).length;
            x.TotalUnsubscribedContacts = newContacts.filter(y => y.IsUnsubscribed).length;
            x.ContactsCount = newContacts.length
        })
    }
    return [...groups];
}

export const updateGroupsInContacts = (contacts: Contact[], groups: Group[]): Contact[] => {
    const updatedContacts = contacts.map(x => {
        const matchingGroups = [...groups.filter(y => y.Contacts?.map(z => z.ID).includes(x.ID))]
        return { ...x, Groups: matchingGroups };
    });

    return updatedContacts;
}


export const handlePushGroupChat = (state: AppState, action): AppState => {
    let { Conversations } = state;

    action.payload.map((x) => {
        let con = Conversations.find(y => y.GroupChat?.ID === x.id)
        if (con) {
            con.GroupChat.UnReadCount = x.unReadCount;
        }
    })
    return {
        ...state, Conversations: [...Conversations]
    }
}

export const PushGroupChatName = (state: AppState, id, name): AppState => {

    let { Conversations } = state;

    let con = Conversations.find(y => y.GroupChat?.ID === id)
    if (con) {
        con.GroupChat.Name = name;
    }
    return {
        ...state, Conversations: [...Conversations]
    }
}


// Convesation Tabs

export class ConversationTabsModel {
    constructor() {
    }

    id: number;
    label: any
    value: string;
    isLoading: boolean;
    data: ConversationCardModel[] | null
}

export interface IConversationCardFlags {
    IsActive: boolean;
    // Index: number;
    // OptionsRef: Element | ((element: Element) => Element) | null | undefined;
}

export type ConversationCardModel = IConversationCardFlags & ConversationModel;

// export type ConversationCardModel = ConversationModel
